#root {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  /* font-family:
    Yuji Syuku,
    serif; */
}

.space-5 {
  > * {
    margin: 5px;
  }
}

.space-10 {
  > * {
    margin: 10px;
  }
}
p {
  text-align: center;
}
